import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// custom components
import Button from "components/CustomButtons/Button.jsx";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Loading from "sections/Loading.jsx";
// external packages
import UserAvatar from "react-user-avatar";
import Firebase from "../firebase";
import { navigate, Link } from "gatsby";
// Auth
import { isAuthenticated, getUserEmail, setCallbackLink } from 'Auth/AuthService';
// styles
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

/*
for Badges

<GridItem xs={1} style={{ textAlign: "center" }}>
  <h4 className={`${classes.cardCategory} ${classes.cardDescription}`} style={{ fontWeight: "bold"}}>55</h4>
  <p>Badges</p>
</GridItem>
<GridItem xs={1} style={{ textAlign: "center" }}>
  <h4 className={`${classes.cardCategory} ${classes.cardDescription}`} style={{ fontWeight: "bold"}}>1</h4>
  <p>Courses</p>
</GridItem>

*/

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        first: "",
        last: "",
        email: getUserEmail(),
        role: ""
      },
      avatar: "",
      recent: []
    }
  }
  componentDidMount() {
    if (!isAuthenticated()) {
      const { pathname } = this.props.location;
      setCallbackLink(pathname);
      navigate("/login")
    } else {
      var update = [];
      const { email } = this.state.user;
      Firebase
        .collection("users")
        .doc(email)
        .get()
        .then(user => {
          this.setState({ user: user.data() });
          const { recent, avatar } = user.data();
          if (avatar) {
            Firebase
              .ref()
              .child(`avatars/${getUserEmail()}.jpg`)
              .getDownloadURL()
              .then(url => {
                this.setState({ avatar: url });
              });
          }
          recent.forEach(course => {
            Firebase
              .collection("courses")
              .where("title", "==", course)
              .get()
              .then(snapshot => {
                snapshot.forEach(doc => {
                  const { description, title, slug, cover } = doc.data();
                  update.push({ description: description.substr(0,450) + "...", title, slug, cover })
                })
              })
            })
        });
        setTimeout(() => {
          this.setState({ recentHeader: update[0], recent: update })
        }, 1000);
    }
  }
  getRecentHeader(recent) {
    console.log(recent)
    if (recent === undefined || recent.length === 0) {
      return <div />
    }
    const { classes } = this.props;
    const courses = recent.map(course => {
      return (
        <GridItem key={course.title}>
          <Card>
            <CardBody style={{ padding: "0" }}>
              <GridContainer>
                <GridItem md={4}>
                  <img alt="" style={{ height: "100%", width: "100%" }} srcSet={course.cover} />
                </GridItem>
                <GridItem md={8}>
                  <GridContainer alignItems="center" justify="center">
                    <GridItem>
                      <h3 style={{ fontWeight: "bold" }}>{course.title}</h3>
                    </GridItem>
                    <GridItem>
                      <div
                        className={classes.cardTitle}
                        dangerouslySetInnerHTML={{ __html: course.description}}
                      />
                    </GridItem>
                    <GridItem style={{ marginBottom: 15 }}>
                      <Link to={`/${course.slug}`} style={{ color: "#fff"}}>
                        <Button
                          color="primary"
                          className={classes.navLink}
                        >
                          continue
                        </Button>
                      </Link>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      )
    })
    return courses;
  }
  getRecent(recent) {
    if (recent === undefined || recent.length === 0) {
      return <div />
    }
    const { classes } = this.props;
    const courses = recent.map(course => {
      return (
        <GridItem xs={4} key={course.title}>
          <Card style={{ textAlign: "center", flex: 1, height: "" }}>
            <CardHeader color="primary">{course.title}</CardHeader>
            <CardBody>
              <div
                className={classes.cardTitle}
                dangerouslySetInnerHTML={{ __html: course.description}}
              />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter} style={{ justifyContent: "center"}}>
              <Link to={`/${course.slug}`} style={{ color: "#fff"}}>
                <Button
                  color="primary"
                  className={classes.navLink}
                >
                  continue
                </Button>
              </Link>
            </CardFooter>
          </Card>
        </GridItem>
      )
    })
    return courses;
  }

  render() {
    const { classes } = this.props;
    const { avatar, user, recent } = this.state;
    const { role, first, last } = user;

    return (
      <div>
        {isAuthenticated() ?
          <div>
            <Header
              headerLink="/"
              brand="Able University"
              color="primary"
              links={<HeaderLinks />}
            />
            <div className={classes.container}>
              <GridContainer alignItems="center">
                <GridItem xs={1}>
                  <UserAvatar
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: 80,
                      width: 80,
                      alignItems: "center"
                    }}
                    size="80"
                    name="Edward Beazer"
                    src={avatar}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <h5 className={classes.cardTitle}>{first} {last}</h5>
                  <h6 className={`${classes.cardCategory} ${classes.cardDescription}`}>{role}</h6>
                </GridItem>
                <GridItem xs={7} />
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <h3 style={{ fontWeight: "bold" }}>Recent</h3>
                </GridItem>
                {recent.length > 1 ? this.getRecentHeader(recent.slice(0,1)) : this.getRecentHeader(recent)}
                {this.getRecent(recent.slice(1,7))}
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <h3 style={{ fontWeight: "bold" }}>Browse Courses</h3>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4}>
                  <Card style={{ textAlign: "center" }}>
                    <CardBody>
                      <h4 className={classes.cardTitle}>Click the button below to view the course catalog</h4>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter} style={{ justifyContent: "center"}}>
                      <Link to="/course-list" style={{ color: "#fff"}}>
                        <Button
                          color="primary"
                          className={classes.navLink}
                        >
                          View Course List
                        </Button>
                      </Link>
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>
              <Footer
                content={
                  <div>
                    <div className={classes.left}>
                      <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://portal.ablehomecarellc.com/dashboard/"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Portal
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://outlook.office365.com"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Webmail
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://policies.ablehomecarellc.com"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Policies
                          </a>
                        </ListItem>
                      </List>
                    </div>
                    <div className={classes.right}>
                      &copy; {1900 + new Date().getYear()} Able Home Care LLC
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        :
        <Loading />
        }
      </div>
    )
  }
};

export default withStyles(landingPageStyle)(HomePage);
